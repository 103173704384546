import React from "react";
import { useTranslation } from "next-i18next";
import Container from "@components/v4/Container";
import MockupDownloadLight from "./images/mockup-download-light.webp";
import MockupDownloadDark from "./images/mockup-download-dark.webp";
import QRCode from "./images/qr-reku-download.svg";
import Image from "next/image";
import { Button, Icons, IconsProps } from "@reku-web/ui";
import Link from "@components/Link";
import { useTheme } from "@emotion/react";
interface CTADownloadProps {
  variant?: "teal" | "purple";
  title?: string;
  subtitle?: string;
  isTnc?: boolean;
  list?: {
    title?: string;
    icon?: IconsProps;
  }[];
}
const CTADownLoad = ({
  variant = "purple",
  title,
  subtitle,
  list,
  isTnc = true
}: CTADownloadProps) => {
  const {
    t
  } = useTranslation("components");
  const isDarkMode = useTheme().mode === "dark";
  const imageMockup = isDarkMode ? MockupDownloadDark.src : MockupDownloadLight.src;
  const defaultLists = [{
    title: t("cta_download.list.1"),
    icon: "CandleStick"
  }, {
    title: t("cta_download.list.2"),
    icon: "BankNote"
  }, {
    title: t("cta_download.list.3"),
    icon: "GiftBox"
  }];
  const lists = list || defaultLists;
  return <Container className='reku-new-theme !py-10 xl:!py-20'>
            <div className='relative overflow-hidden bg-background-neutral-inverse dark:bg-dark-background-card-main rounded-lg'>
                <div className='flex flex-col-reverse xl:flex-row gap-6 xl:gap-10'>
                    <div className='flex-1 xl:max-w-[600px] p-4 pt-0 xl:pl-10 xl:py-[55px] space-y-3 xl:space-y-4'>
                        <h1 className='text-heading-md xl:text-heading-2xl text-text-neutral-onImageStrong'>
                            {title ? title : t("cta_download.title")}
                        </h1>
                        <p className='text-body-md xl:text-body-lg text-text-neutral-onImageSubtle'>
                            {subtitle ? subtitle : t("cta_download.subtitle")}
                        </p>
                        <div className='space-y-2.5 xl:space-y-3'>
                            {lists.map(({
              title,
              icon
            }) => <div className='flex gap-2'>
                                    <Icons icon={icon as IconsProps} className='w-6 h-6 text-icon-color-teal-duotone' />
                                    <span className='text-md xl:text-lg xl:leading-6 font-bold text-text-neutral-onImageStrong'>
                                        {title}
                                    </span>
                                </div>)}
                        </div>
                        <Link href='https://reku.onelink.me/5mL7/web' target='_blank' className='xl:!hidden'>
                            <Button variant='grayOutline' size='md' block className='!mt-6 !text-dark-text-neutral-inverse !bg-dark-actions-grey-active'>
                                {t("common:download")}
                            </Button>
                        </Link>
                        {isTnc && <p className='text-body-sm xl:text-body-sm text-text-neutral-onImageSubtle pt-2'>
                                {t("cta_download.term_condition")}
                            </p>}
                    </div>

                    <div className='relative z-[1] xl:px-0 pt-4 xl:pt-0 flex-1 flex justify-center xl:items-end xl:justify-end'>
                        <Image src={imageMockup} width={410.19} height={448.64} alt='mockup-download-light' className='hidden xl:block' />
                        <div className='xl:hidden w-[276px] h-[255px] overflow-hidden ml-10'>
                            <Image src={imageMockup} width={276} height={302.97} alt='mockup-download-light' />
                        </div>

                        <div className='hidden xl:flex flex-col gap-6 items-center pb-[44px] -translate-x-10'>
                            <QRCode />
                            <p className='text-heading-xs text-text-neutral-onImageStrong text-center max-w-[192px]'>
                                {t("cta_download.scan_qr")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='absolute right-0 top-0 h-[283px] xl:h-full w-1/2 xl:w-[441px]' style={{
        background: variant === "teal" ? "linear-gradient(180deg, #1BA7AC 0%, rgba(111, 76, 179, 0) 68.64%)" : "linear-gradient(180deg, #6F4CB3 0%, #2B4551,  rgba(27, 167, 172, 0) 68.64%)"
      }} />
            </div>
        </Container>;
};
export default CTADownLoad;